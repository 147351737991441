"use client";

import bgImage from "@/public/png/home/meet-expert/meet-expert-bg.png";
import bgImageSM from "@/public/png/home/meet-expert/mb-expert-img.png"
import circlePattern from "@/public/svg/home/circle-pattern.svg";

import "@/styles/common/typo.scss";
import "@/styles/home/meet-expert.scss";

import Button from "@/components/button/Button";
import Heading from "@/components/heading/heading";

import Image from "next/image";
import { Link } from "react-scroll";

const MeetExpert = () => {
    return (
        <section className="meet-expert-section flex-center">
            <Image loading="lazy"
                src={bgImage}
                alt="circle pattern"
                className="bg-image hidden lg:block"

            />

            <Image loading="lazy"
                src={bgImageSM}
                alt="circle pattern"
                className="bg-image lg:hidden"
            />
            <div className="meet-expert-container container px-4  mx-auto">
                <div className="meet-expert-inner flex flex-col items-center text-white text-center gap-3 lg:gap-6 py-5 xl:py-0">
                    <div className="meet-expert-pattern flex flex-col items-center">
                        <Image loading="lazy"
                            src={circlePattern}
                            alt="circle pattern"
                            className="circle-pattern w-11 md:w-20"

                        />
                        <small className="font-yesteryear font-extralight pt-4">Our Travel Planners</small>
                    </div>
                    <Heading as="h2">
                        Consult Them for Seamless Trip Planning
                    </Heading>
                    <p className="lead text-white">
                        Our travel planners ensure hassle-free adventures, curating itineraries tailored to your preferences.

                    </p>
                    <Link to="hero">
                        <Button className="flex items-center" variant="orange">
                            <span>Talk To Our Experts</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default MeetExpert;
