"use client";

import Image from "next/image";

import "@/styles/common/typo.scss";
import "./budget-friendly.scss";

import Title from "@/components/title/Title";
import Heading from "@/components/heading/heading";

import Redirect from "@/public/svg/redirect.svg";
import Bali from "@/public/png/home/budget-friendly/bali.png";
import Dubai from "@/public/png/home/budget-friendly/dubai.png";
import Maldives from "@/public/png/home/budget-friendly/maldives.png";
import Singapore from "@/public/png/home/budget-friendly/singapore.png";
import Thailand from "@/public/png/home/budget-friendly/thailand.png";
import Link from "next/link";

const BudgetFriendly = () => {
    const DestinationsOne = [
        {
            name: "Bali",
            picture: Bali,
            note: "Starting from ₹26,000",
            path: "bali/honeymoon-packages",
        },
        {
            name: "Singapore",
            picture: Singapore,
            note: "Starting from ₹40,000",
            path: "singapore/honeymoon-packages",
        },
    ];
    const DestinationsTwo = [
        {
            name: "Thailand",
            picture: Thailand,
            note: "Starting from ₹22,500",
            path: "thailand/honeymoon-packages",
        },
    ];
    const DestinationsThree = [
        {
            name: "Maldives",
            picture: Maldives,
            note: "Starting from ₹45,000",
            path: "maldives/honeymoon-packages",
        },
        {
            name: "Dubai",
            picture: Dubai,
            note: "Starting from ₹41,000",
            path: "dubai/honeymoon-packages",
        },
    ];
    return (
        <section className="budget-friendly-section py-10 sm:pb-20 md:pb-48 sm:pt-20 flex-center">
            <div className="budget-friendly-container container px-4 mx-auto flex flex-col gap-6 sm:gap-14">
                <Title title="Magical Honeymoon Hideaways" />
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                    <div className="budget-friendly-block col-span-1 flex flex-col sm:flex-row lg:flex-col gap-4 lg:col-span-1 xl:col-span-1">
                        {DestinationsOne.map((o, index) => {
                            return (
                                <Link
                                    href={o.path}
                                    key={index}
                                    className="inline-flex flex-col gap-6 text-white ml-auto budget-friendly-one"
                                >
                                    <figure>
                                        <Image loading="lazy"
                                            src={o.picture}
                                            alt={o.name}
                                            sizes="(max-width:768px) 100vw, (max-width: 1200px) 25vw, 30vw"
                                            
                                        />
                                        <Image loading="lazy"
                                            src={Redirect}
                                            alt="Redirect"
                                            className="redirect"
                                            
                                        />
                                        <figcaption className="flex flex-col items-start justify-end p-6">
                                            <Heading
                                                as="h2"
                                                className="font-yesteryear font-extralight flex-center"
                                            >
                                                {o.name}
                                            </Heading>
                                            <span className="font-light text-white">
                                                {o.note}
                                            </span>
                                        </figcaption>
                                    </figure>
                                </Link>
                            );
                        })}
                    </div>
                    <div className="budget-friendly-block col-span-1 flex flex-col sm:flex-row lg:flex-col gap-4 lg:col-span-1 xl:col-span-1">
                        {DestinationsTwo.map((t, index) => {
                            return (
                                <Link
                                    href={t.path}
                                    key={index}
                                    className="inline-flex flex-col gap-6 text-white budget-friendly-two"
                                >
                                    <figure>
                                        <Image loading="lazy"
                                            src={t.picture}
                                            alt={t.name}
                                            sizes="(max-width:768px) 100vw, (max-width: 1200px) 25vw, 30vw"

                                            
                                        />
                                        <Image loading="lazy"
                                            src={Redirect}
                                            alt="Redirect"
                                            className="redirect"
                                            width={36}
                                            height={36}
                                            
                                        />
                                        <figcaption className="flex flex-col items-start justify-end p-6">
                                            <Heading
                                                as="h2"
                                                className="font-yesteryear font-extralight flex-center"
                                            >
                                                {t.name}
                                            </Heading>
                                            <span className="font-light text-white">
                                                {t.note}
                                            </span>
                                        </figcaption>
                                    </figure>
                                </Link>
                            );
                        })}
                    </div>
                    <div className="budget-friendly-block col-span-1 flex flex-col sm:flex-row lg:flex-col gap-4 lg:col-span-1 xl:col-span-1">
                        {DestinationsThree.map((t, index) => {
                            return (
                                <Link
                                    href={t.path}
                                    key={index}
                                    className="inline-flex flex-col gap-6 text-white mr-auto budget-friendly-three"
                                >
                                    <figure>
                                        <Image loading="lazy"
                                            src={t.picture}
                                            alt={t.name}
                                            sizes="(max-width:768px) 100vw, (max-width: 1200px) 25vw, 30vw"
                                            
                                        />
                                        <Image loading="lazy"
                                            src={Redirect}
                                            alt="Redirect"
                                            className="redirect"
                                            width={36}
                                            height={36}
                                            
                                        />
                                        <figcaption className="flex flex-col items-start justify-end p-6">
                                            <Heading
                                                as="h2"
                                                className="font-yesteryear font-extralight flex-center"
                                            >
                                                {t.name}
                                            </Heading>
                                            <span className="font-light text-white">
                                                {t.note}
                                            </span>
                                        </figcaption>
                                    </figure>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BudgetFriendly;
