"use client";

import Image from "next/image";

import Bali from "@/public/png/home/hand-picked/bali.jpg";
import Maldives from "@/public/png/home/hand-picked/maldives.jpg";
import Singapore from "@/public/png/home/hand-picked/singapore.jpg";
import Thailand from "@/public/png/home/hand-picked/thailand.jpg";
import Dubai from "@/public/png/home/hand-picked/dubai.jpg";
import Andaman from "@/public/png/home/hand-picked/andaman.jpg";
import Srilanka from "@/public/png/home/hand-picked/srilanka.jpg";
import Vietnam from "@/public/png/home/hand-picked/vietnam.jpg";
import Malaysia from "@/public/png/home/hand-picked/malaysia.jpg";
import Hongkong from "@/public/png/home/hand-picked/hongkong.jpg";



import PaginationLeft from "@/public/svg/paginarition-arrow-left.svg";
import PaginationRight from "@/public/svg/paginarition-arrow-right.svg";

import "./hand-picked.scss";

// Import Swiper styles
import DestinationCard from "@/components/destinationCard/destinationCard";
import Title from "@/components/title/Title";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Link from "next/link";

const HandPickedList = [
    {
        name: "Thailand",
        picture: Thailand,
        note: "Starting from ₹19,000",
        path: "thailand/family-packages",
    },
    {
        name: "Bali",
        picture: Bali,
        note: "Starting from ₹25,000",
        path: "bali/family-packages",
    },
    {
        name: "Maldives",
        picture: Maldives,
        note: "Starting from ₹42,000",
        path: "maldives/family-packages",
    },
    {
        name: "Singapore",
        picture: Singapore,
        note: "Starting from ₹40,000",
        path: "singapore/family-packages",
    },
    {
        name: "Malaysia",
        picture: Malaysia,
        note: "Starting from ₹29,000",
        path: "malaysia/family-packages",
    },
    {
        name: "Dubai",
        picture: Dubai,
        note: "Starting from ₹41,000",
        path: "dubai/family-packages",
    },
    {
        name: "Hongkong",
        picture: Hongkong,
        note: "Starting from ₹55,499",
        path: "hongkong/family-packages",
    },
    {
        name: "Andaman",
        picture: Andaman,
        note: "Starting from ₹16,500",
        path: "andaman/family-packages",
    },
    {
        name: "Srilanka",
        picture: Srilanka,
        note: "Starting from ₹24,000",
        path: "srilanka/family-packages",
    },
    {
        name: "Vietnam",
        picture: Vietnam,
        note: "Starting from ₹17,500",
        path: "vietnam/family-packages",
    },
];

const HandPicked = () => {
    return (
        <section className="hand-picked-section py-10 md:py-20 flex flex-col gap-6 sm:gap-14">
            <div className="hand-picked-container container mx-auto px-4">
                <Title title="Tailor-Made for Everyone" />
            </div>
            <div className="hand-picked-slider px-4">
                <Swiper
                    spaceBetween={16}
                    slidesPerView={1}
                    navigation={{
                        nextEl: ".pagination-right-hand-picked",
                        prevEl: ".pagination-left-hand-picked",
                        enabled: true,
                    }}
                    modules={[Navigation]}
                    breakpoints={{
                        639: {
                            slidesPerView: 2,
                        },
                        767: {
                            slidesPerView: 3,
                        },
                        1023: {
                            slidesPerView: 4,
                        },
                        1279: {
                            slidesPerView: 5,
                        },
                    }}
                    className="hand-picked-swiper"
                >
                    {HandPickedList.map((h, index) => {
                        return (
                            <SwiperSlide
                                key={index}
                                className="hand-picked-slide"
                            >
                                <DestinationCard
                                    name={h.name}
                                    picture={h.picture}
                                    note={h.note}
                                    path={h.path}
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <div className="swiper-pagination-wrapper container px-4 mx-auto flex items-center justify-end gap-2 mt-10">
                    <div className="pagination-arrows flex items-center gap-3 sm:gap-4">
                        <figure className="pagination-left-hand-picked flex-center">
                            <Image loading="lazy"
                                src={PaginationLeft}
                                alt="Pagination Left"
                                
                            />
                        </figure>
                        <figure className="pagination-right-hand-picked flex-center">
                            <Image loading="lazy"
                                src={PaginationRight}
                                alt="Pagination Right"
                                
                            />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HandPicked;
